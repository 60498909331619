<template>
    <div class="main-container">
        <Header />   
        <div class="slider-area">     
            <div class="single-slider hero-overly slider-height2 d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1>{{titulosecao}}</h1>
                                <b-breadcrumb class="page-breadcrumb" :items="items">
                                    
                                </b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog-grid-area section-space--inner--50">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 posts-list">
                        <div class="single-post">
                  <div class="feature-img">
                      <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + noticias.foto" class="img-fluid" :alt="noticias.titulo">
                  </div>
                   <h2>{{noticias.resumo}}
                     </h2>
                     <p class="excert" v-html="noticias.conteudo">{{noticias.conteudo}}</p>
               </div>
                        
                    </div>                
                </div>
            </div>
        </div>       
        <Footer />
        <!-- <OffCanvasMobileMenu /> -->
        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Footer from '../components/Footer'
   // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

    import axios from 'axios'
    export default {
        components: {
            Header,
            Footer,
            //OffCanvasMobileMenu
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        data() {
            return {
                titulosecao: "",
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: this.$route.params.pag,
                        active: true
                    }
                ],
                noticias: [],
               
            }
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - notícia`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }

    function doSomething() {
        var id = this.$route.params.id;  
        var slug = this.$route.params.slug;
        console.log(id);
        axios.get(`https://sistema.gouarts.com/api/noticia/`+id+`/`+slug+`/?cliente=vitoriainspecoes`).then(res => {
            this.noticias = res.data.noticias;
            this.titulosecao = res.data.noticias.titulo;           
                      
        }).catch(err => {
            console.log(err);
        })
}
</script>
<style scoped>
.slider-area {
    height:250px;
}
</style>

